<template>
  <v-select
    :items="units"
    :label="$helpers.string.capitalizeI18N('validity-unit')"
    v-bind="$attrs"
    v-on="$listeners"
    clearable
    :placeholder="$helpers.string.capitalizeI18N('no-validity')"
  >
    <template v-slot:selection="data">
      {{ getValidityName(data.item) }}
    </template>
    <template v-slot:item="data">
      {{ getValidityName(data.item) }}
    </template></v-select
  >
</template>

<script>
export default {
  name: "MembershipTypeValidityUnitSelector",
  data() {
    return {
      units: ["day", "week", "month", "year"],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    getValidityName(unit) {
      switch (unit) {
        case "day":
          return this.$helpers.string.capitalizeI18N("day");
        case "week":
          return this.$helpers.string.capitalizeI18N("week");
        case "month":
          return this.$helpers.string.capitalizeI18N("month");
        case "year":
          return this.$helpers.string.capitalizeI18N("year");
        default:
          return unit;
      }
    },
    clearClicked() {
      this.$attrs["value"] = null;
      this.$emit("input", this.$attrs["value"]);
    },
  },
};
</script>