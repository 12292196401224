<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="dialog" :return-value.sync="membershipType" persistent max-width="800px"
      @keydown.esc="close()" :fullscreen="$helpers.display.mobileDialog" :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            membershipType.id == null
              ? $helpers.string.capitalizeI18N("membership-type.creation")
              : membershipType.name
          }}</span>
          <v-switch v-show="membershipType.id" v-model="membershipType.active" class="ml-4"
            :label="$helpers.string.capitalizeI18N('active')"></v-switch>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('name', {
                    required: true,
                  })
                    " v-model="membershipType.name" required autofocus
                    :rules="$helpers.rule.notEmpty()"></v-text-field>
                </v-row>

                <v-row>
                  <v-col>
                    <h3>Séances</h3>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col>
                    <v-checkbox v-model="noLimit" :label="`Séances illimitées`"
                      @change="(value) => membershipType.unit = value ? null : membershipType.unit"></v-checkbox>
                    <v-text-field v-show="!noLimit" :label="$helpers.string.capitalizeI18N('number-of-units')"
                      v-model="membershipType.unit" type="number"
                      :placeholder="$helpers.string.capitalizeI18N('no-limit')" clearable @click:clear="noLimit = true"
                      :rules="unitRules"></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col>
                    <v-checkbox v-model="noLimitClient" :label="`Pas de limite par client`"
                      @change="(value) => membershipType.nbMaxPerClient = value ? null : membershipType.nbMaxPerClient"></v-checkbox>
                    <v-text-field v-show="!noLimitClient" :label="$helpers.string.capitalizeI18N('number-max-per-client')
                      " v-model="membershipType.nbMaxPerClient" type="number"
                      :placeholder="$helpers.string.capitalizeI18N('no-limit')" persistent-hint
                      :hint="$t('number-max-per-client-hint')" clearable></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <h3>Durée de validité</h3>
                    <v-checkbox v-model="noExpiration" :label="`Pas d'expiration`"
                      @change="noExpirationChanged"></v-checkbox>
                  </v-col>
                </v-row>

                <v-row no-gutters v-show="!noExpiration">
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('validity-value')"
                      v-model="membershipType.validityValue" type="number" clearable></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col>
                    <MembershipTypeValidityUnitSelector v-model="membershipType.validityUnit" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <h3>Tarif</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('price-vat-included')
                      " v-model="membershipType.priceVatIncluded" type="number"
                      :placeholder="$helpers.string.capitalizeI18N('free')" clearable></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('vat-rate', {
                      required: true,
                    })
                      " v-model="membershipType.vatRate" type="number" suffix="%" required
                      :rules="$helpers.rule.notEmpty()"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch v-model="membershipType.publicAvailable" :label="membershipType.publicAvailable ? $t('membership-type.public-available-yes') :
                      $t('membership-type.public-available-no')"></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton @click="saveAndClose" type="save" ref="saveButton" />
          <!-- <CardActionButton @click="saveAndNew" i18ntext="saveandnew" /> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import MembershipTypeValidityUnitSelector from "./MembershipTypeValidityUnitSelector";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";

export default {
  name: "MembershipTypeEditionPopup",
  data: () => ({
    dateBegin: new Date().toISOString().substring(0, 10),
    dateEnd: new Date().toISOString().substring(0, 10),
    dialog: false,
    noLimit: false,
    noExpiration: false,
    noLimitClient: false,
    membershipType: {},
    unitRules: [
      // v => !!v || "This field is required",
      v => (v && v >= 0) || "Ce chiffre doit être supérieur à 0",
      // v => (v && v <= 50000) || "Max should not be above £50,000",
    ],
  }),
  components: {
    CardActionButton,
    MembershipTypeValidityUnitSelector,
    FormGroupElement,
    PopupCloseButton,
  },
  methods: {
    noExpirationChanged(value) {
      if (value) {
        this.membershipType.validityUnit = null;
        this.membershipType.validityValue = null;
      }
    },
    async saveMembershipType() {
      this.membershipType.dateBegin = this.dateBegin;
      this.membershipType.dateEnd = this.dateEnd;

      try {
        await this.$services.membershipType.save(this.membershipType);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadMembershipType() {
      this.dateBegin = this.membershipType.dateBegin
        ? new Date(this.membershipType.dateBegin).toISOString().substr(0, 10)
        : new Date().toISOString().substring(0, 10);
      this.dateEnd = this.membershipType.dateEnd
        ? new Date(this.membershipType.dateEnd).toISOString().substr(0, 10)
        : new Date().toISOString().substring(0, 10);
      this.noLimit = this.membershipType.unit == null;
      this.noExpiration = !this.membershipType.validityUnit && !this.membershipType.validityValue;
      this.noLimitClient = this.membershipType.nbMaxPerClient == null;
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.saveMembershipType();

        this.$helpers.snackbar.showSuccessI18N("membership-type.save.success");

        this.$emit("onSaveClicked", this.membershipType);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveMembershipType();
      this.$emit("onSaveClicked", this.membershipType);
      this.membershipType = {};
      this.loadMembershipType();
      this.$refs.firstName.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(membershipType) {
      this.membershipType = membershipType;
      this.loadMembershipType();
      this.dialog = true;
    },
  },
};
</script>